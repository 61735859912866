import React from 'react'
import SectionHeader from '../../../shared/components/SectionBody/SectionHeader'
import SectionSubHeader from '../../../shared/components/SectionBody/SectionSubHeader'
import ConfigureCards from './cards/ConfigureCards'
import TableForm from './TableForm/TableForm'
import ServicesCard from './cards/ServicesCard'



const ConfigurationHome = () => {
    const header = 'Configuration',
        subHeader = "Configure Your Pharmacy Location"

    return (
        <div>
            <div className=" flex justify-between items-center">
                <div className='flex flex-col gap-2 mt-4 mb-4'>
                    <SectionHeader header={header} />
                    <SectionSubHeader subHeader={subHeader} />
                </div>

            </div>
            <div className=''>
                <ConfigureCards />
            </div>

            <ServicesCard/>
        </div>
    )
}

export default ConfigurationHome
