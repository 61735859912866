import { combineReducers } from "@reduxjs/toolkit";

import InventorySlice from "./../features/Inventory/Slices/InventorySlice";
import FormularySlice from "../features/NationalMedicineFormulary/Slices/FormularySlice";
import authSlice from "../features/Register/Slices/authSlice";
import subscriptionSlice from "./../features/Dashoard/slices/subscriptionSlice";
import featureSlice from "../features/Dashoard/slices/featureSlice";
import configSlice from "../features/Configuration/slices/configSlice";

const rootReducer = combineReducers({
  inventory: InventorySlice,
  formulary: FormularySlice,
  auth: authSlice,
  subscription: subscriptionSlice,
  features: featureSlice,
  configs: configSlice,
});

export default rootReducer;
