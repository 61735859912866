import React from "react";

const CardBody = ({ image, name, id, label, relativeLocation, contact }) => {
  return (
    <div className="flex w-full items-center gap-6 p-4 border rounded-lg shadow-sm">
      {image && (
        <img
          src={image}
          alt={`${label} Image`}
          className="w-40 h-40 border border-gray-300 object-cover"
        />
      )}

      {/* Details Section */}
      <div className="right-desc pr-20 flex flex-col">
        <h2 className="text-lg font-semibold text-gray-800">{name}</h2>
        {relativeLocation && (
          <p className="text-sm text-gray-600">
            <span className="font-medium">City:</span> {relativeLocation.city}
          </p>
        )}
        {contact && (
          <p className="text-sm text-gray-600">
            <span className="font-medium">Phone:</span> {contact.phoneNumber}
          </p>
        )}
        {contact && (
          <p className="text-sm text-gray-600">
            <span className="font-medium">Email:</span> {contact.email}
          </p>
        )}
      </div>
      <div className="left-desc py-12 px-10 border-l-4 border-gray-500 flex flex-col">
        {relativeLocation && (
          <p className="text-sm text-gray-600">
            <span className="font-medium">License:</span>{" "}
            {relativeLocation.city}
          </p>
        )}
        {contact && (
          <p className="text-sm text-gray-600">
            <span className="font-medium">Subscription:</span>{" "}
            {contact.phoneNumber}
          </p>
        )}
        {contact && (
          <p className="text-sm text-gray-600">
            <span className="font-medium">Email:</span> {contact.email}
          </p>
        )}
      </div>
    </div>
  );
};

export default CardBody;
