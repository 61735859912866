import { createSlice } from "@reduxjs/toolkit";
import {
  getAllProducts,
  getProductCategories,
  deleteProducts,
  updateProduct,
  addProduct,
  getLowStockProducts,
  newlyAddedProducts
} from "./InventoryAction.js";

const initialState = {
  loading: false,
  loadingAllProducts: false,
  loadingProduct: false,
  categories: [],
  loadingCategories: false,
  error: null,
  inventories: [],
  deleteProduct: false,
  updateProduct: false,
  addProduct: false,
  success: true,
  products: [],
  product: [],
  myproducts: [],
  message: "",
  singleInventory: [],
  addedProducts: [], // Track added products
  updatedProducts: [], // Track updated products
  deletedProducts: [], // Track deleted products
  lowStockProducts:[],
  loadingLowStock: false,
  newlyAddedProducts:[],
  loadingNewlyAdded:false
};

const InventorySlice = createSlice({
  name: "inventory",
  initialState,
  reducers: {
    clearTracking(state) {
      // state.addedProducts = [];
      // state.updatedProducts = [];
      // state.deletedProducts = [];
      state.updateProduct=false
    },
  },
  extraReducers: (builder) => {
    // Get All Products
    builder.addCase(getAllProducts.pending, (state) => {
      state.loadingAllProducts = true;
      state.error = null;
    });
    builder.addCase(getAllProducts.fulfilled, (state, action) => {
      state.loadingAllProducts = false;
      state.error = null;
      state.products = action.payload;
    });
    builder.addCase(getAllProducts.rejected, (state, action) => {
      state.loadingAllProducts = false;
      state.error = action.error.message;
    });

    // Get Product Categories
    builder.addCase(getProductCategories.pending, (state) => {
      state.loadingCategories = true;
      state.error = null;
    });
    builder.addCase(getProductCategories.fulfilled, (state, action) => {
      state.loadingCategories = false;
      state.error = null;
      state.categories = action.payload;
    });
    builder.addCase(getProductCategories.rejected, (state, action) => {
      state.loadingCategories = false;
      state.error = action.error.message;
    });

    // Add Product
    builder.addCase(addProduct.pending, (state) => {
      state.addProduct = true;
      state.error = null;
    });
    builder.addCase(addProduct.fulfilled, (state, action) => {
      state.addProduct = false;
      state.error = null;
      state.addedProducts.push(action.payload); // Add to tracking
      state.products.push(action.payload); // Update product list
    });
    builder.addCase(addProduct.rejected, (state, action) => {
      state.addProduct = false;
      state.error = action.error.message;
    });

    // Update Product
    builder.addCase(updateProduct.pending, (state) => {
      state.updateProduct = false;
      state.error = null;
    });
    builder.addCase(updateProduct.fulfilled, (state, action) => {
      state.updateProduct = true;
      state.error = null;
      const updatedProduct = action.payload;
      state.updatedProducts.push(updatedProduct); // Add to tracking
      // state.products = state.products.map((product) =>
      //   product.id === updatedProduct.id ? updatedProduct : product
      // ); // Update product list
    });
    builder.addCase(updateProduct.rejected, (state, action) => {
      state.updateProduct = false;
      console.log(action)
      state.error = action.error.message;
    });

    // Delete Product
    builder.addCase(deleteProducts.pending, (state) => {
      state.deleteProduct = true;
      state.error = null;
    });
    builder.addCase(deleteProducts.fulfilled, (state, action) => {
      state.deleteProduct = false;
      state.error = null;
      const deletedProductId = action.payload;
      state.deletedProducts.push(deletedProductId); // Add to tracking
      state.products = state.products.filter(
        (product) => product.id !== deletedProductId
      ); // Update product list
    });
    builder.addCase(deleteProducts.rejected, (state, action) => {
      state.deleteProduct = false;
      state.error = action.error.message;
    });

    builder.addCase(getLowStockProducts.pending, (state) => {
      state.loadingLowStock = true;
      state.error = null;
    });
    builder.addCase(getLowStockProducts.fulfilled, (state, action) => {
      state.loadingLowStock = false;
      state.error = null;
      console.log(action.payload)
      state.lowStockProducts = action.payload;
    });
    builder.addCase(getLowStockProducts.rejected, (state, action) => {
      state.loadingLowStock = false;
      // state.error = action.error.message;
    });

    builder.addCase(newlyAddedProducts.pending, (state) => {
      state.loadingNewlyAdded = true;
      state.error = null;
    });
    builder.addCase(newlyAddedProducts.fulfilled, (state, action) => {
      state.loadingNewlyAdded = false;
      state.error = null;
      console.log(action.payload)
      state.newlyAddedProducts = action.payload;
    });
    builder.addCase(newlyAddedProducts.rejected, (state, action) => {
      state.loadingNewlyAdded = false;
      // state.error = action.error.message;
    });
  },
});

export const { clearTracking } = InventorySlice.actions;
export default InventorySlice.reducer;
