import React from 'react'
import SectionSubHeader from '../../../shared/components/SectionBody/SectionSubHeader'
import SectionHeader from '../../../shared/components/SectionBody/SectionHeader'
import EditForm from '../InventoryItem/Create/Components/EditForm'
import { useLocation } from "react-router-dom";
export default function EditProduct() {
    const header = 'Edit products',
        subHeader = "You can leave them as they are"
        const location = useLocation();
        const { data: productData } = location.state || {}; // Retrieve productData from state
    
        if (!productData) {
            return <p>Product data not found. Please go back and try again.</p>;
        }
  return (
    <div className='pl-8 pb-4'>


        <div>
            <div className=" flex justify-between items-center mb-8">
                <div className='flex flex-col gap-2 mt-4'>
                    <SectionHeader header={header} />
                    <SectionSubHeader subHeader={subHeader} />
                </div>
            </div>

            <EditForm productData={productData} />

        </div>
        </div>
  )
}
