import React, { useEffect } from "react";
import ActionButton from "./ActionButton";
import { useDispatch, useSelector } from "react-redux";
import { getLowStockProducts } from "../../Slices/InventoryAction";

const TableBody = () => {
  const dispatch = useDispatch();

  // Selectors for accessing state
  const products = useSelector((state) => state.inventory.lowStockProducts || []);
  const loading = useSelector((state) => state.inventory.loading);
  const error = useSelector((state) => state.inventory.error);

  // Get the pharmacyId from the user's information
  const userInfo = useSelector((state) => state.auth.user);
  const pharmacyId = userInfo?.healthService?._id;

  // Fetch low-stock products on component mount
  useEffect(() => {
    if (pharmacyId) {
      dispatch(getLowStockProducts(pharmacyId));
    }
  }, [dispatch, pharmacyId]);

  // Render based on loading or error states
  if (loading) {
    return (
      <tbody>
        <tr>
          <td colSpan="5" className="text-center py-4">
            Loading...
          </td>
        </tr>
      </tbody>
    );
  }

  if (error) {
    return (
      <tbody>
        <tr>
          <td colSpan="5" className="text-center py-4 text-red-500">
            {error}
          </td>
        </tr>
      </tbody>
    );
  }

  return (
    <tbody>
      {products.length > 0 ? (
        products.map((product, index) => (
          <tr key={index} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
            <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
              {product.name}
            </th>
            <td className="px-6 py-4">{product.category?.name || "N/A"}</td>
            <td className="px-6 py-4">{product.inventory?.available_quantity || "N/A"}</td>
            <td className="px-6 py-4">{product.single_package_price || "N/A"}</td>
            <td className="px-6 py-4">
              <ActionButton />
            </td>
          </tr>
        ))
      ) : (
        <tr>
          <td colSpan="5" className="text-center py-4">
            No low-stock products found.
          </td>
        </tr>
      )}
    </tbody>
  );
};

export default TableBody;
