// eslint-disable-next-line no-unused-vars
import React from "react";
import { useForm } from "react-hook-form";
import { Link, useNavigate } from "react-router-dom";
import InputText from "../../../shared/components/FormInputs/InputText";
import InputPassword from "../../../shared/components/FormInputs/InputPassword";
import InputButton from "../../../shared/components/FormInputs/InputButton";
import { login } from "../../Register/Slices/authSlice";
import { useDispatch } from "react-redux";

const LoginForm = () => {
  const { register, handleSubmit, reset } = useForm();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const submitForm = async (data) => {
    console.log("datttta", data);
    try {
      const res = await dispatch(login(data)).unwrap();
      console.log(res);
      reset();
      navigate("/");
    } catch (error) {
      console.error("login failed:", error);
    }
  };

  return (
    <div>
      <form className="space-y-4 md:space-y-6">
        <InputText
          name="phoneNumber"
          label="Phone Number"
          type="tel"
          placeholder="Enter phone number"
          register={register}
          required
        />

        <InputPassword
          name="password"
          label="Password"
          type="password"
          placeholder="••••••••"
          register={register}
        />

        <div className="flex items-center justify-between">
          <Link
            to="/admin/auth/forgot"
            className="text-sm font-medium text-[#23826E] text-primary-600 hover:underline dark:text-primary-500"
          >
            Forgot password?
          </Link>
        </div>
        <InputButton
          label="Sign in "
          onClick={() => handleSubmit(submitForm)()}
        />
        <div className="flex items-center justify-center gap-1 text-black">
          <span>Don't have an account? </span>
          <Link
            to="/auth/register"
            className="text-sm font-medium text-[#23826E] text-primary-600 hover:underline dark:text-primary-500"
          >
            Register
          </Link>
        </div>
      </form>
    </div>
  );
};
export default LoginForm;
