import { createSlice } from "@reduxjs/toolkit";
import { fetchFeatures } from "./featureActions";


const initialState = {
  loading: false,
  error: null,
  features: null,
};

const featureSlice = createSlice({
  name: "features",
  initialState,
  reducers: {},
  extraReducers: (builder) => {

    builder.addCase(fetchFeatures.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(fetchFeatures.fulfilled, (state,  action ) => {

      state.loading = false;
      state.error = null;
      state.features = action.payload.data;
    });
    builder.addCase(fetchFeatures.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error.message;
    });
  },
});

export default featureSlice.reducer;
