import React from "react";
import { selectUserInfo } from "../../../Register/Slices/authSlice";
import { useSelector } from "react-redux";

const ServiceCard = ({ header, children }) => {
  const userInfo = useSelector(selectUserInfo);
  console.log("llll", userInfo);
  return (
    <div className="w-full bg-white  rounded border  border-opacity-30">
      <div className="flex justify-between items-center border-b border-gray-800/30">
        <h2 className=" py-5">
          <span className="px-4">{header}</span>
        </h2>

        <div className="pr-4">Edit</div>
      </div>

      <div className="p-4">
        <div className="mb-2">
            <img src='https://via.placeholder.com/100' className="mx-auto " />
        </div>
        <div className="service-desc">
            <p>We bring ...</p>
        </div>
      </div>
    </div>
  );
};

export default ServiceCard;
