import React, { useEffect, useState } from "react";
import "react-toastify/dist/ReactToastify.css"; // Import Toastify styles
import InputText from "../../../../../shared/components/FormInputs/InputText";
import { useForm } from "react-hook-form";
import TextArea from "../../../../../shared/components/FormInputs/TextArea";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from 'react-router-dom';
import { clearTracking } from "../../../Slices/InventorySlice";
import {
  getProductCategories,
  updateProduct,
} from "../../../Slices/InventoryAction";
import { toast } from 'react-toastify';

const EditForm = ({ productData }) => {
  const { register, handleSubmit, setValue } = useForm();
  const navigate = useNavigate();

  const dispatch = useDispatch();
  const {
    categories,
    updateProduct: updateProductSuccess,
    error,
  } = useSelector((state) => state.inventory);

  useEffect(() => {
    dispatch(getProductCategories());
    if (productData) {
      // Prefill the form with existing product data
      Object.keys(productData).forEach((key) => {
        if (key === "category") {
          setValue("category", productData.category?.id || "");
        } else {
          setValue(key, productData[key]);
        }
      });
    }
  }, [dispatch, productData, setValue]);

  useEffect(() => {
    // Show a toast when the product update is successful
    if (updateProductSuccess) {
      toast.success("Product updated successfully!");
      navigate('/inventory/product-list'); 
      dispatch(clearTracking());


    }
    // Show a toast for errors, if any
    if (error) {
        console.log(error);
      toast.error(`${error}`);
    }
  }, [updateProductSuccess, error]);

  const submitHandler = (data) => {
    const selectedCategory = categories.find(
      (category) => category._id === data.category
    );

    const updatedProductData = {
      ...data,
      category: {
        id: selectedCategory?._id,
        name: selectedCategory?.name,
      },
      single_package_price: parseInt(data.single_package_price),
      available_quantity: parseInt(data.available_quantity),
      max_stock_quantity: parseInt(data.max_stock_quantity),
      min_stock_quantity: parseInt(data.min_stock_quantity),
      reorder_point: parseInt(data.reorder_point),
    };
    const id = productData.id;
    dispatch(updateProduct({ productId: id, updatedProductData }));
  };

  return (
    <>
      <form onSubmit={handleSubmit(submitHandler)}>
        <div className="grid gap-6 mb-6 md:grid-cols-3">
          <InputText
            label="Name"
            required
            name="name"
            placeholder="Name..."
            register={register}
          />
          <InputText
            label="Packaging"
            required
            name="packaging"
            placeholder="Packaging..."
            register={register}
          />
          <InputText
            label="Bar code"
            required
            name="barcode"
            placeholder="Barcode..."
            register={register}
          />
          <InputText
            label="Batch Number"
            name="batch_number"
            placeholder="Batch Number..."
            register={register}
          />
          <InputText
            label="Available Quantity"
            type="number"
            name="available_quantity"
            placeholder="Available Quantity..."
            register={register}
          />
          <InputText
            label="Reorder Point"
            type="number"
            name="reorder_point"
            placeholder="Reorder Point..."
            register={register}
          />
          <InputText
            label="Minimum Quantity"
            type="number"
            name="min_stock_quantity"
            placeholder="Minimum stock Quantity..."
            register={register}
          />
          <InputText
            label="Maximum Quantity"
            type="number"
            name="max_stock_quantity"
            placeholder="Maximum stock Quantity..."
            register={register}
          />
          <InputText
            label="Single Price"
            type="number"
            name="single_package_price"
            placeholder="Single Package price..."
            register={register}
          />
          <InputText
            label="Expiration Date"
            required
            type="date"
            name="expiration_date"
            placeholder="Expiration Date..."
            register={register}
          />
          <InputText
            label="Brand"
            type="text"
            required
            name="brand"
            placeholder="Product Brand"
            register={register}
          />
          <InputText
            label="Supplier"
            type="text"
            required
            name="supplier"
            placeholder="Supplier name"
            register={register}
          />
        </div>

        <div className="grid gap-6 mb-6 md:grid-cols-1">
          <div>
            <label
              htmlFor="categories"
              className="block mb-2 text-sm font-medium text-gray-900"
            >
              Select a category
            </label>
            <select
              id="categories"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
              {...register("category")}
            >
              {categories.length > 0 &&
                categories.map((category) => (
                  <option
                    value={category._id}
                    key={category._id}
                    selected={productData.category?.id === category._id}
                  >
                    {category.name}
                  </option>
                ))}
            </select>
          </div>
          <TextArea
            label="Description"
            name="description"
            placeholder="Product Description..."
            register={register}
          />
        </div>

        <button
          type="submit"
          className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center"
        >
          Update
        </button>
      </form>
    </>
  );
};

export default EditForm;
