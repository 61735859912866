import React from "react";
import { Route, Routes,Navigate } from "react-router-dom";
import Dashboard from "./features/Dashoard/Pages/Dashboard";
import Inventory from "./features/Inventory/Pages/Inventory";
import Report from "./features/Report/Pages/Report";
import ListofMedicine from "./features/ListofMedicine/Pages/ListofMedicine";
import NationalMedicineFormulary from "./features/NationalMedicineFormulary/Pages/NationalMedicineFormulary";
import SingleListOfMedicine from "./features/SingleListOfMedicine/Pages/SingleListOfMedicinePage";
import SalesReportPage from "./features/SalesReport/Pages/SalesReportPage";
import ConfiguratonPage from "./features/Configuration/Pages/ConfiguratonPage";
import MedicineGroupsPages from "./features/MedicineGroups/Pages/MedicineGroupsPages";
import SingleMedicineGroupsPage from "./features/SingleMedicineGroups/Pages/SingleMedicineGroupsPage";
import NewMedicineGroupPage from "./features/NewMedicineGroup/Page/NewMedicineGroupPage";
import LoginPage from "./features/Login/Page/LoginPage";
import CreateInventory from "./features/Inventory/Pages/CreateInventory";
import DetailInventoryPage from "./features/Inventory/Pages/DetailInventoryPage";
import SingleInventoryItem from "./features/Inventory/Pages/SingleInventoryItem";
import InvoicePage from "./features/Invoice/Pages/InvoicePage";
import RegisterPage from "./features/Register/Page/RegisterPage";
import { useSelector } from "react-redux";
import EditProduct from "./features/Inventory/Pages/EditProduct";
const AppRoutes = () => {
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  return (
    <Routes>
      {/* Authentication authorization routes */}
      <Route
        path="/auth/login"
        element={<LoginPage />}
      />
      <Route path="/auth/register" element={<RegisterPage />} />

      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="/inventory" element={<Inventory />} />
      {/* Inventory sub routes */}
      <Route path="/inventory/add-item" element={<CreateInventory />} />
      <Route path="/inventory/product-list" element={<DetailInventoryPage />} />
      <Route path="/inventory/edit-item" element={<EditProduct />} />

      <Route
        path="/inventory/product/detail/:productId"
        element={<SingleInventoryItem />}
      />

      <Route path="/invoice" element={<InvoicePage />} />

      <Route path="/report" element={<Report />} />
      <Route path="/sales-report" element={<SalesReportPage />} />
      <Route path="/sales-report" element={<SalesReportPage />} />
      <Route path="/configuration" element={<ConfiguratonPage />} />
      <Route path="/medicine-group" element={<MedicineGroupsPages />} />
      <Route
        path="/medicine-group/:medicineGroupId"
        element={<SingleMedicineGroupsPage />}
      />

      <Route path="/medicine-group/new" element={<NewMedicineGroupPage />} />

      <Route path="/list-of-medicine" element={<ListofMedicine />} />
      <Route
        path="/list-of-medicine/:medicineId"
        element={<SingleListOfMedicine />}
      />

      <Route
        path="/national-medicine-formulary"
        element={<NationalMedicineFormulary />}
      />

      <Route path="/" element={ !isAuthenticated ? <Navigate to="/auth/login" replace /> : <Dashboard/>} />
    </Routes>
  );
};

export default AppRoutes;
