import React, { useState, useEffect } from "react";
import CardBody from "./CardBody";
import EditModal from "./EditModal";
import { useDispatch, useSelector } from "react-redux";
import { fetchConfig } from "../../slices/configActions";
import { selectUserInfo } from "../../../Register/Slices/authSlice";

const Card = ({ header }) => {
  const userInfo = useSelector(selectUserInfo) 
  const id = userInfo?.healthService._id
  console.log('kkk',id)
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Fetch config data
  useEffect(() => {
    const fetchData = async () => {
      if (id) {
        try {
         const res = await dispatch(fetchConfig(id)).unwrap(); // Unwrap to handle errors directly
         console.log('tttt',res)
        } catch (error) {
          console.error("Failed to fetch config:", error);
        }
      }
    };

    fetchData();
  }, [dispatch, id]);

  // Select config state from Redux
  const { config, loading, error } = useSelector((state) => state.configs);
  console.log('coooo',config?.data)

  const handleEdit = () => {
    setIsModalOpen(true);
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleModalSubmit = (updatedData) => {
    console.log("Updated Data:", updatedData);
    // Handle the updated data (e.g., update the Redux store or send to API)
  };

  return (
    <div className="bg-white p-4 rounded border border-opacity-30 shadow-sm">
      <div className="flex justify-between items-center border-b border-gray-800/30 px-4 py-5">
        <h2 className="text-lg font-bold text-gray-800">{header}</h2>
        <button
          className="text-blue-600 hover:underline disabled:opacity-50"
          onClick={handleEdit}
          disabled={loading || !!error}
        >
          Edit
        </button>
      </div>

      {loading ? (
        <div className="p-4 text-gray-600">Loading...</div>
      ) : error ? (
        <div className="p-4 text-red-600">Error: {error}</div>
      ) : (
        <div className="flex p-4">
          <CardBody
            image={
                config?.data.image || "https://via.placeholder.com/100"
            }
            label="Pharmacy Details"
            name={config?.data.name || "N/A"}
            relativeLocation={config?.data.relativeLocation || "N/A"}
            contact={config?.data.contact || "N/A"}
          />
        </div>
      )}

      {/* Edit Modal */}
      <EditModal
        isOpen={isModalOpen}
        onClose={handleModalClose}
        onSubmit={handleModalSubmit}
        initialData={{
          _id: config?.data._id || "",
          name: config?.data.name || "",
          city: config?.data.relativeLocation?.city || "",
          phoneNumber: config?.data.contact?.phoneNumber || "",
          email: config?.data.contact?.email || "",
          image: config?.data.image || "",
        }}
      />
    </div>
  );
};

export default Card;
