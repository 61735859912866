/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import pharmacyImg from '../../../../assets/images/pharmacy.png'
import { Button } from '@material-tailwind/react'
import { useLocation } from 'react-router-dom'
import { GetSingleProduct } from '../../../../assets/JS/Inventory/Category/Product'

const SingleItemDetail = () => {

    const location = useLocation();
  
    // console.log(productData)
    const { data: productData } = location.state || {}; // Retrieve productData from state
    
    // eslint-disable-next-line no-unused-vars
    const [data, setData] = useState(productData || [])
    const [inventory, setInventory] = useState([])


    useEffect(() => {

        const getSingle = async () => {

            try {
                const { data, success } = await GetSingleProduct(23);
                console.log(data.inventory)
                if (success) {
                    const { available_quantity, min_stock_quantity, max_stock_quantity, reorder_point } = data.inventory

                    setInventory(prev => prev = ({ available_quantity, min_stock_quantity, max_stock_quantity, reorder_point }))
                }
            } catch (error) {

            }
        }

        // if (inventory.length === 0) getSingle()
        getSingle()

        console.log(inventory.length)
    }, [data])



    return (
        <div>

            {/* 1 the first part detail content */}
            <div className='flex gap-8'>

                {/* Image */}
                <div className='basis-2/5'>
                    <img src={pharmacyImg} alt="" className='w-full' />
                </div>

                {/* PRODUCT DETAIL RIGHT SIDE */}
                <div className='basis-3/5 flex flex-col gap-3 justify-between'>
                    <h3 className='font-bold text-black text-2xl'>{data.name}</h3>
                    <h5>{data.single_package_price} ETB</h5>
                    <p className='font-poppins'>{data.description}</p>
                    <Button color='green'>Re Order point</Button>
                </div>
            </div>

            {/* 2. THe second part details */}
            <div className='border border-gray-500 w-full rounded-xl p-8 mt-12 flex gap-8'>
                <div className='flex gap-0 '>
                    <ul class="w-48 text-sm  text-gray-900 bg-white/60 border-l border-gray-200 rounded-bl-lg rounded-tl-lg  dark:bg-gray-700 dark:border-gray-600 dark:text-white font-bold">
                        <li class="w-full px-4 py-2  border-b border-r  border-gray-200  dark:border-gray-600">Category</li>
                        <li class="w-full px-4 py-2  border-b border-r  border-gray-200 dark:border-gray-600">Brand</li>
                        <li class="w-full px-4 py-2  border-b border-r  border-gray-200 dark:border-gray-600">Packaging</li>

                        <li class="w-full px-4 py-2  border-b  border-r border-gray-200 dark:border-gray-600">Available Qty</li>
                        <li class="w-full px-4 py-2  border-b  border-r border-gray-200 dark:border-gray-600">Maximum Qty</li>
                        <li class="w-full px-4 py-2  border-b  border-r border-gray-200 dark:border-gray-600">Minimum Qty</li>
                        <li class="w-full px-4 py-2  border-r  rounded-bl-lg">Re order Point</li>
                    </ul>

                    <ul class="w-72 text-sm font-medium text-gray-900 bg-white border-r border-gray-200 rounded-br-lg rounded-tr-lg dark:bg-gray-700 dark:border-gray-600 dark:text-white">
                        <li class="w-full px-4 py-2 border-b border-gray-200 rounded-t-lg dark:border-gray-600">{data.category}</li>
                        <li class="w-full px-4 py-2 border-b border-gray-200 dark:border-gray-600">{data.brand}</li>
                        <li class="w-full px-4 py-2 border-b border-gray-200 dark:border-gray-600">{data.packaging}</li>

                        <li class="w-full px-4 py-2 border-b border-gray-200 dark:border-gray-600">{inventory?.available_quantity}</li>

                        <li class="w-full px-4 py-2 border-b border-gray-200 dark:border-gray-600">{inventory?.max_stock_quantity}</li>

                        <li class="w-full px-4 py-2 border-b border-gray-200 dark:border-gray-600">{inventory?.min_stock_quantity}</li>

                        <li class="w-full px-4 py-2 rounded-br-lg">{inventory?.reorder_point}</li>
                    </ul>



                </div>


            </div>
        </div>
    )
}

export default SingleItemDetail