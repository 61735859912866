import React from "react";
import { TbListDetails } from "react-icons/tb";
import { MdDeleteOutline } from "react-icons/md";
import { FiEdit } from "react-icons/fi";

const ActionButton = ({ onViewDetails, onEdit, onDelete }) => {
  return (
    <div className="inline-flex rounded-md shadow-sm" role="group">
      {/* View Details Button */}
      <button
        onClick={onViewDetails}
        className="inline-flex items-center px-4 py-2 text-sm font-medium text-gray-100 bg-blue-700 hover:text-blue-700 hover:bg-white border border-gray-200 rounded-l-lg focus:z-10 focus:ring-2 focus:ring-blue-700 dark:bg-gray-800 dark:border-gray-700 dark:text-white transition-all duration-300"
      >
        <TbListDetails />
        <span className="pl-2">View</span>
      </button>

      {/* Edit Button */}
      <button
        onClick={onEdit}
        className="inline-flex items-center px-4 py-2 text-sm font-medium text-green-500 bg-white border-t border-b border-gray-200 hover:bg-gray-100 hover:text-blue-700 focus:z-10 focus:ring-2 focus:ring-blue-700 dark:bg-gray-800 dark:border-gray-700 dark:text-white transition-all duration-300"
      >
        <FiEdit />
        <span className="pl-2">Edit</span>
      </button>

      {/* Delete Button */}
      <button
        onClick={onDelete}
        className="inline-flex items-center px-4 py-2 text-sm font-medium hover:text-red-700 hover:bg-white border border-gray-200 rounded-r-lg text-gray-100 bg-red-700 focus:z-10 focus:ring-2 focus:ring-blue-700 dark:bg-gray-800 dark:border-gray-700 dark:text-white transition-all duration-300"
      >
        <MdDeleteOutline />
        <span className="pl-2">Delete</span>
      </button>
    </div>
  );
};

export default ActionButton;
