import { createSlice } from "@reduxjs/toolkit";
import { updateConfig, fetchConfig } from "./configActions";

const initialState = {
  loading: false,
  error: null,
  configs: null, // Array of configurations
  config: null,  // Individual configuration (fetched by ID)
};

const configSlice = createSlice({
  name: "configs",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // Handle updateConfig
    builder.addCase(updateConfig.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(updateConfig.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
      const updatedConfig = action.payload;

      // Update the configs array if it exists
      if (state.configs) {
        state.configs = state.configs.map((config) =>
          config.id === updatedConfig.id ? updatedConfig : config
        );
      } else {
        state.configs = [updatedConfig];
      }

      // Update the individual config (if present in state)
      if (state.config && state.config.id === updatedConfig.id) {
        state.config = updatedConfig;
      }
    });
    builder.addCase(updateConfig.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload || "Failed to update configuration.";
    });

    // Handle fetchConfig
    builder.addCase(fetchConfig.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(fetchConfig.fulfilled, (state, action) => {
      state.loading = false;
      state.error = null;
      state.config = action.payload; // Store the fetched individual config
    });
    builder.addCase(fetchConfig.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload || "Failed to fetch configuration.";
    });
  },
});

export default configSlice.reducer;
