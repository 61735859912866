import React from "react";
import Card from "./Card";

const ConfigureCards = () => {
  return (
    <div className="">
      <div className="w-full mb-8">
        <Card header="General" />
      </div>
    </div>
  );
};

export default ConfigureCards;
