import React, { useState, useEffect } from "react";
import RegisterForm from "../Component/RegisterForm";
import MapContainers from "../Component/MapContainers";

const RegisterPage = () => {
  const [coordinates, setCoordinates] = useState([7.6731, 36.8356]);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          setCoordinates([latitude, longitude]);
          console.log('cooord',coordinates)
        },
        (error) => {
          setError(error.message);
        }
      );
    } else {
      setError("Geolocation is not supported.");
    }
  }, []);

  const onSubmit = (data) => {
    console.log({ ...data, coordinates });
  };

  return (
    <div className="flex flex-col md:flex-row p-6">
      <RegisterForm coordinates ={coordinates} onSubmit={onSubmit} />
      {error && <p className="text-red-500">{error}</p>}
      <MapContainers
        coordinates={coordinates}
        setCoordinates={setCoordinates}
      />
    </div>
  );
};

export default RegisterPage;
