import React, { useEffect } from "react";
import ActionButton from "./ActionButton";
import { useDispatch, useSelector } from "react-redux";
import { newlyAddedProducts, deleteProducts, updateProduct } from "../../Slices/InventoryAction";
import { useNavigate } from "react-router-dom";

const TableBody = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  // Selectors for accessing state
  const products = useSelector((state) => state.inventory.newlyAddedProducts || []);
  const loading = useSelector((state) => state.inventory.loadingNewlyAdded);
  const error = useSelector((state) => state.inventory.error);

  const userInfo = useSelector((state) => state.auth.user);
  const pharmacyId = userInfo?.healthService?._id;

  // Fetch newly added products
  useEffect(() => {
    if (pharmacyId) {
      dispatch(newlyAddedProducts({ pharmacyId, limit: 5 }));
    }
  }, [dispatch, pharmacyId]);

  const handleDelete = async (productId) => {
    const confirmDelete = window.confirm("Are you sure you want to delete this product?");
    if (confirmDelete) {
      dispatch(deleteProducts(productId))
        .unwrap()
        .then(() => alert("Product deleted successfully."))
        .catch((err) => alert(`Error deleting product: ${err}`));
    }
  };

  const handleEdit = (productId) => {
    // Navigate to the edit product page
    navigate(`/products/edit/${productId}`);
  };

  const handleViewDetails = (productId) => {
    // Navigate to the view product details page
    navigate(`/products/details/${productId}`);
  };

  // Render based on loading or error states
  if (loading) {
    return (
      <tbody>
        <tr>
          <td colSpan="5" className="text-center py-4">
            Loading...
          </td>
        </tr>
      </tbody>
    );
  }

  if (error) {
    return (
      <tbody>
        <tr>
          <td colSpan="5" className="text-center py-4 text-red-500">
            {error}
          </td>
        </tr>
      </tbody>
    );
  }

  return (
    <tbody>
      {products.length > 0 ? (
        products.map((product) => (
          <tr key={product._id} className="bg-white border-b dark:bg-gray-800 dark:border-gray-700">
            <th scope="row" className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white">
              {product.name}
            </th>
            <td className="px-6 py-4">{product.category?.name || "N/A"}</td>
            <td className="px-6 py-4">{product.inventory?.available_quantity || "N/A"}</td>
            <td className="px-6 py-4">{product.single_package_price || "N/A"}</td>
            <td className="px-6 py-4">
              <ActionButton
                onViewDetails={() => handleViewDetails(product.id)}
                onEdit={() => handleEdit(product.id)}
                onDelete={() => handleDelete(product.id)}
              />
            </td>
          </tr>
        ))
      ) : (
        <tr>
          <td colSpan="5" className="text-center py-4">
            No newly added products found.
          </td>
        </tr>
      )}
    </tbody>
  );
};

export default TableBody;
