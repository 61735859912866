import React, { useEffect, useState } from "react";
import pharmacy from "../../../../assets/images/pharmacy.png";
import { Link } from "react-router-dom";
import style from "./style.module.css";
import { fetchFeatures } from "../../slices/featureActions";
import { useDispatch, useSelector } from "react-redux";

const FeaturedPharmacetucials = () => {
  const dispatch = useDispatch();
  const [features, setFeatures] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const getFeatures = async () => {
      try {
        setLoading(true);
        const response = await dispatch(fetchFeatures()).unwrap(); 
        console.log('fff',response.data.length)
        setFeatures(response.data); 
        setLoading(false);
      } catch (err) {
        setError(err.message || "Failed to fetch features");
        setLoading(false);
      }
    };

    getFeatures();
  }, [dispatch]);
  return (
    <div className="mt-3">
     {
      features.map((feature) =>(
        <div key={feature._id} className=" border-t-0 border pt-1 border-green-500 rounded-lg bg-[#2D5F4C] pb-1">
        <div className="overflow-hidden">
          <img src={pharmacy} alt="" className="w-full  object-fill" />
        </div>

        {/* Pharmacy description */}
        <div className="p-1 mt-2 flex flex-col justify-start font-poppins gap-1">
          <h3 className={style.pharma_head}>{feature.title}</h3>

          <p className={style.pharm_desc}>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Ullam error
            magni odio nesciunt debitis{" "}
          </p>

          <Link
            to="/"
            className="bg-[#399B76] text-white text-center basis-1 rounded-lg"
          >
            <span className="w-full text-sm font-bold p-2 ">See more</span>
          </Link>
        </div>
      </div>
      ))
     }
    </div>
  );
};

export default FeaturedPharmacetucials;
