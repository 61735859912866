import { createAsyncThunk } from "@reduxjs/toolkit";


import api from "../../../services/apiService";

export const fetchFeatures = createAsyncThunk(
  "features/fetchFeatures",
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.get("adsapi/ads");
      return response.data;
    } catch (error) {
      return rejectWithValue(error.response.data);
    }
  }
);
