
import { createAsyncThunk } from "@reduxjs/toolkit";

// import { endPoint } from "../../../assets/JS/api.js";

import api from "../../../services/apiService";

export const getAllProducts = createAsyncThunk(
  "Inventory/products",
  async (pharmacyId, { rejectWithValue }) => {
    try {
      const response = await api.get(`/inventoryapi/products/${pharmacyId}/products`);
         console.log(response.data)
       
         return response.data.products;

      }
     
        catch (error) {
        

      // It return a custom error message from backend if present
      if (error.response && error.response.data.message) {
        
        return rejectWithValue(error.response.data.message);
      } else {
       
        return rejectWithValue(error.message);
      }
    }
  }
);

export const getProductCategories = createAsyncThunk(
  "Inventory/ProductCategories",
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.get("/productapi/product-categories");
      
       console.log(response.data.data)
         return response.data.data;
      }
     
        catch (error) {
     
      if (error.response && error.response.data.message) {
       
        return rejectWithValue(error.response.data.message);
      } else {
   
        return rejectWithValue(error.message);
      }
    }
  }
);

export const addProduct = createAsyncThunk('inventory/addProduct', async (orderData, { dispatch, rejectWithValue }) => {
  try {
    
    const response = await api.post('/inventoryapi/products/', orderData);
   
     console.log(response.data)
   
    return response.data.product.product;
    
  } catch (error) {
   console.log(error)
    return rejectWithValue(error.response.data);
  }
});

// const addProductToInventory = async (prodId, data) => {
//   console.log(prodId);
//   console.log(data)
//   try {
//     const response = await api.post(`/inventoryapi/inventories/${prodId}/create`, data);
//     console.log(response.data);
//     return response.data;
//   } catch (error) {
//     console.log(error);
//     throw error;
//   }
// };


export const deleteProducts = createAsyncThunk(
  "Inventory/deleteProducts",
  async (productId, { rejectWithValue }) => {
    console.log("as gaheen ture")
    try {
      const response = await api.delete(`/inventoryapi/products/${productId}`);
         console.log(response.data)
       
         return response.data.products;

      }
     
        catch (error) {
        

      // It return a custom error message from backend if present
      if (error.response && error.response.data.message) {
        
        return rejectWithValue(error.response.data.message);
      } else {
       
        return rejectWithValue(error.message);
      }
    }
  }
);

export const updateProduct = createAsyncThunk('inventory/updateProduct', async ({productId,updatedProductData}, { dispatch, rejectWithValue }) => {
  try {
    console.log(updatedProductData)
    const response = await api.patch(`/inventoryapi/products/${productId}`, updatedProductData);
   
     console.log(response.data)
   
    return response.data.product.product;
    
  } catch (error) {
   console.log(error.response)
   if (error.response && error.response.data.message) {
        
    return rejectWithValue(error.response);
  } else {
   
    return rejectWithValue(error.message);
  }
  }
});


export const getLowStockProducts = createAsyncThunk(
  "Inventory/getLowStockProducts",
  async (pharmacyId, { rejectWithValue }) => {
    try {
      const response = await api.get(`/inventoryapi/products/${pharmacyId}/below-reorder`);
         console.log(response.data)
       
         return response.data.products;

      }
     
        catch (error) {
        

      // It return a custom error message from backend if present
      if (error.response && error.response.data.message) {
        
        return rejectWithValue(error.response.data.message);
      } else {
       
        return rejectWithValue(error.message);
      }
    }
  }
);


export const newlyAddedProducts = createAsyncThunk(
  "Inventory/newlyAddedProducts",
  async ({ pharmacyId, limit = 5 }, { rejectWithValue }) => {
    try {
      // Make the API call with pharmacyId and limit as query parameters
      const response = await api.get(`/inventoryapi/products/${pharmacyId}/products`, {
        params: { limit },
      });

      console.log(response.data);

      return response.data.products;
    } catch (error) {
      // Return a custom error message from the backend if present
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      } else {
        return rejectWithValue(error.message);
      }
    }
  }
);
