import React from "react";
import ServiceCard from "./ServiceCard";

const ServicesCard = () => {
  return (
    <div>
      <div className="grid grid-cols-3 gap-6">
        <ServiceCard header="service 1" />
        <ServiceCard header="service 2" />
        <ServiceCard header="service 3" />
      </div>
    </div>
  );
};

export default ServicesCard;
