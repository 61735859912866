import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../services/apiService";

// Update an existing config
export const updateConfig = createAsyncThunk(
  "configs/updateConfig", 
  async (data, { rejectWithValue }) => {
    try {
        console.log('heyeye',data)
      const response = await api.patch(
        `userapi/health-organizations/${data._id}/`, 
        data
      );
      return response.data; 
    } catch (error) {
      return rejectWithValue(
        error.response?.data || "An error occurred while updating the organization."
      );
    }
  }
);

// Fetch a specific config by ID
export const fetchConfig = createAsyncThunk(
  "features/fetchConfig",
  async (id, { rejectWithValue }) => {
    try {
      const response = await api.get(`userapi/health-organizations/${id}/`);
      return response.data;
    } catch (error) {
      return rejectWithValue(
        error.response?.data || "An error occurred while fetching the organization."
      );
    }
  }
);
